import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import StaticLayoutMDX, { Twitter } from '../../../templates/static-page-mdx.js';
export const _frontmatter = {
  "helmet": "nofollow"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = StaticLayoutMDX;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Listing of the fantasy sports experts that the Fantasy Judge evaluates`}</h2>
    <h3><a parentName="h3" {...{
        "href": "https://www.4for4.com",
        "target": "_blank",
        "rel": "nofollow"
      }}>{`4for4`}</a>{` `}<Twitter handle="4for4football" mdxType="Twitter"></Twitter></h3>
    <p>{`4for4 is one of the longest-running fantasy football analysis websites, whose team of contributors have backgrounds in statistics, poker, sports journalism, and of course, football. Not being distracted with other sports has helped 4for4 garnish awards, such as being ranked among the Top 5 in accuracy by FantasyPros 6 times between 2010 and 2017, including 2 #1 finishes. Josh Moore`}<Twitter handle="4for4_Josh" mdxType="Twitter"></Twitter>{`owns the site and leads its analytic team.`}</p>
    <h3><a parentName="h3" {...{
        "href": "https://www.thehuddle.com",
        "target": "_blank",
        "rel": "nofollow"
      }}>{`TheHuddle`}</a>{` `}<Twitter handle="thehuddle" mdxType="Twitter"></Twitter></h3>
    <p>{`Today The Huddle is part of the USA Today network, but it was founded in 1997 by `}<a parentName="p" {...{
        "href": "http://thehuddle.com/author/david-dorey/",
        "target": "_blank",
        "rel": "nofollow"
      }}>{`David Dorey`}</a><Twitter handle="dmdorey" mdxType="Twitter"></Twitter>{`and `}<a parentName="p" {...{
        "href": "http://thehuddle.com/author/wwalters/",
        "target": "_blank",
        "rel": "nofollow"
      }}>{`Whit Walters`}</a>{`. The Huddle provided exclusive content to Fox Sports when it launched its fantasy product in 1999, and in 2004 it partnered with Pearson to author the Fantasy Football Handbook, which was voted Best Magazine by the Fantasy Sports Trade Association (FSTA). The `}<a parentName="p" {...{
        "href": "https://audioboom.com/channel/the-blitzed-podcast",
        "target": "_blank",
        "rel": "nofollow"
      }}>{`Blitzed Podcast`}</a>{` originates from The Huddle.`}</p>
    <h3><a parentName="h3" {...{
        "href": "https://www.thefantasyfootballadvice.com/",
        "target": "_blank",
        "rel": "nofollow"
      }}>{`TheFantasyFootballAdvice`}</a></h3>
    <p>{`TheFFAdvice is a newer, independent site that focuses on analytics, founded by Nick Zylak`}<Twitter handle="nickzylakffa" mdxType="Twitter"></Twitter>{`and Dare Maybury`}<Twitter handle="daremayburyffa" mdxType="Twitter"></Twitter>{`. The team is extremely active on social media, including a popular `}<a parentName="p" {...{
        "href": "https://www.youtube.com/channel/UCK4vtDxY3_2Bm5H6VQsuNTA",
        "target": "_blank",
        "rel": "nofollow"
      }}>{`YouTube channel`}</a>{` and `}<a parentName="p" {...{
        "href": "https://twitter.com/NickZylakFFA",
        "target": "_blank",
        "rel": "nofollow"
      }}>{`Twitter feeds`}</a>{`. Their YouTube content is also available via `}<a parentName="p" {...{
        "href": "https://open.spotify.com/show/1FPryoHaqkX1T6irSsJF0T",
        "target": "_blank",
        "rel": "nofollow"
      }}>{`podcast`}</a>{`. Nick and Dare also make themselves available to subscribers via private Facebook chat groups.`}</p>
    <h3><a parentName="h3" {...{
        "href": "https://www.draftsharks.com",
        "target": "_blank",
        "rel": "nofollow"
      }}>{`DraftSharks`}</a>{` `}<Twitter handle="draftsharks" mdxType="Twitter"></Twitter></h3>
    <p>{`Co-founded by `}<a parentName="p" {...{
        "href": "https://www.draftsharks.com/why-join#wj_ftffw",
        "target": "_blank",
        "rel": "nofollow"
      }}>{`Lenny Pappano`}</a>{` in 1999, DraftSharks now employs writers and analysts with extensive backgrounds in fantasy sports expertise, including a $100,000 Daily Fantasy Sports winner. Since 2012, DraftSharks has 6 first-place finishes from Fantasy Pros and FSTA contests, and were ranked 3rd in FantasyPros 2018 Weekly Rankings Contest.`}</p>
    <h3><a parentName="h3" {...{
        "href": "https://www.CBS.com",
        "target": "_blank",
        "rel": "nofollow"
      }}>{`CBS`}</a></h3>
    <p>{`CBS's fantasy rankings are provided by Jamey Eisenberg`}<Twitter handle="jameyeisenberg" mdxType="Twitter"></Twitter>{`, Dave Richard`}<Twitter handle="daverichard" mdxType="Twitter"></Twitter>{`, and Heath Cummings`}<Twitter handle="heathcummingssr" mdxType="Twitter"></Twitter>{`. This team hosts `}<a parentName="p" {...{
        "href": "https://www.cbssports.com/fantasy/football/video/fantasy-football-today/",
        "target": "_blank",
        "rel": "nofollow"
      }}>{`Fantasy Football Today`}</a>{` on CBS.com and `}<a parentName="p" {...{
        "href": "https://podcasts.apple.com/us/podcast/cbssports-com-fantasy-football/id261735167?mt=2",
        "target": "_blank",
        "rel": "nofollow"
      }}>{`Fantasy Football Today Podcast`}</a>{`. Eisenberg has been recognized by Fantasy Pros as having among the most accurate Fantasy Football Draft rankings since the site started tracking experts in 2010. Cummings previously hosted on ESPN's Kansas City affiliate, and Richard was NFL.com's first fantasy writer.`}</p>
    <h3><a parentName="h3" {...{
        "href": "https://www.ESPN.com",
        "target": "_blank",
        "rel": "nofollow"
      }}>{`ESPN`}</a></h3>
    <p>{`The Worldwide Leader's weekly rankings come from Matthew Berry`}<Twitter handle="matthewberrytmr" mdxType="Twitter"></Twitter>{`, Eric Karabell`}<Twitter handle="karabellespn" mdxType="Twitter"></Twitter>{`, Field Yates`}<Twitter handle="fieldyates" mdxType="Twitter"></Twitter>{`, Tristan H. Cockroft`}<Twitter handle="sultanofstat" mdxType="Twitter"></Twitter>{`, Mike Clay`}<Twitter handle="mikeclaynfl" mdxType="Twitter"></Twitter>{`and Stephania Bell`}<Twitter handle="stephania_espn" mdxType="Twitter"></Twitter>{`.`}</p>
    <h3><a parentName="h3" {...{
        "href": "https://www.thefantasyfootballers.com",
        "target": "_blank",
        "rel": "nofollow"
      }}>{`FantasyFootballers`}</a>{` `}<Twitter handle="theffballers" mdxType="Twitter"></Twitter></h3>
    <p>{`The Fantasy Footballers `}{`–`}{` Andy Holloway`}<Twitter handle="andyholloway" mdxType="Twitter"></Twitter>{`, Jason Moore`}<Twitter handle="jasonffl" mdxType="Twitter"></Twitter>{`, and Mike Wright`}<Twitter handle="ffhitman" mdxType="Twitter"></Twitter>{` `}{`–`}{` have produced an award-winning fantasy football `}<a parentName="p" {...{
        "href": "https://tunein.com/podcasts/Fantasy-Sports/The-Fantasy-Footballers-Podcast-p656661/",
        "target": "_blank",
        "rel": "nofollow"
      }}>{`podcast`}</a>{` since 2014. Accolades include 2019 "Best Sports Podcast" by iHeartRadio Podcast Awards and "Best Fantasy Football Podcast" by the Fantasy Sports Writers Association in both 2017 and 2018. Their predictions have also garnered Top 5 accuracy awards by Fantasy Pros in 2017 and 2018, and they can also be heard on `}<a parentName="p" {...{
        "href": "https://www.siriusxm.com/fantasysportsradio",
        "target": "_blank",
        "rel": "nofollow"
      }}>{`SiriusXM`}</a>{`.`}</p>
    <h3><a parentName="h3" {...{
        "href": "https://www.fantasysharks.com/",
        "target": "_blank",
        "rel": "nofollow"
      }}>{`FantasySharks`}</a>{` `}<Twitter handle="fantasysharks" mdxType="Twitter"></Twitter></h3>
    <p>{`FantasySharks launched in 2003, disseminating fantasy football content on the web for free. The Sharks `}<a parentName="p" {...{
        "href": "https://www.fantasysharks.com/apps/Projections/WeeklyProjections.php?pos=ALL",
        "target": "_blank",
        "rel": "nofollow"
      }}>{`weekly player rankings`}</a>{` are among the most comprehensive, providing not just stat projections but commentary on every player's weekly trends, matchup, and co-existence with teammates. FantasySharks also host very popular community `}<a parentName="p" {...{
        "href": "https://www.fantasysharks.com/forum/viewforum.php",
        "target": "_blank",
        "rel": "nofollow"
      }}>{`forums`}</a>{`.`}</p>
    <h3><a parentName="h3" {...{
        "href": "https://fantasysixpack.net/",
        "target": "_blank",
        "rel": "nofollow"
      }}>{`FantasySixpack`}</a>{` `}<Twitter handle="fantasysixpack" mdxType="Twitter"></Twitter></h3>
    <p><a parentName="p" {...{
        "href": "https://fantasysixpack.net/about-us/",
        "target": "_blank",
        "rel": "nofollow"
      }}>{`Joe Bond`}</a><Twitter handle="f6p_joe" mdxType="Twitter"></Twitter>{`started Fantasy Sixpack in 2009, and also co-hosts `}<a parentName="p" {...{
        "href": "https://fantasysixpack.net/category/podcast/",
        "target": "_blank",
        "rel": "nofollow"
      }}>{`The Fantasy Six Pack Hour Podcast`}</a>{`. His rankings were among the Top 5, as measured by FantasyPros, in 2014. The site contributors include popular, insightful writers with unique fantasy perspectives. The Sixpack weekly rankings are a compilation of Bond and 4 other analysts.`}</p>
    <h3><a parentName="h3" {...{
        "href": "https://www.fftoday.com/",
        "target": "_blank",
        "rel": "nofollow"
      }}>{`FFToday`}</a>{` `}<Twitter handle="ff_today" mdxType="Twitter"></Twitter></h3>
    <p>{`FFToday has been publishing fantasy expertise for over 20 years. `}<a parentName="p" {...{
        "href": "https://www.fftoday.com/articles/krueger/",
        "target": "_blank",
        "rel": "nofollow"
      }}>{`Mike Krueger`}</a>{` leads the team of writers publishing up-to-date projections and analysis, providing practical, actionable information (start/sit, waiver wire) for fantasy team owners.`}</p>
    <h3><a parentName="h3" {...{
        "href": "https://www.footballdiehards.com/",
        "target": "_blank",
        "rel": "nofollow"
      }}>{`FootballDiehards`}</a>{` `}<Twitter handle="ffdiehards" mdxType="Twitter"></Twitter></h3>
    <p>{`If you still read printed, hard-copy content like the Fantasy Judge does, then you've probably picked up some FootballDiehards content, as they publish 4 distinct pre-season magazines. Senior Editor `}<a parentName="p" {...{
        "href": "https://www.footballdiehards.com/fantasy-football-columns/bob-harris/index.cfm",
        "target": "_blank",
        "rel": "nofollow"
      }}>{`Bob Harris`}</a><Twitter handle="footballdiehard" mdxType="Twitter"></Twitter>{` has been writing about fantasy sports since 1993 and won FSWA's "Writer of the Year" in 2005. The Diehards' web site is a firehose of breaking news, injury reports, live updates, and of course unique articles and analysis. Yes, they also have a `}<a parentName="p" {...{
        "href": "http://footballdiehardspod.libsyn.com/",
        "target": "_blank",
        "rel": "nofollow"
      }}>{`podcast`}</a>{`.`}</p>
    <h3><a parentName="h3" {...{
        "href": "https://fulltimefantasy.com/",
        "target": "_blank",
        "rel": "nofollow"
      }}>{`FulltimeFantasy`}</a>{` `}<Twitter handle="fulltimefantasy" mdxType="Twitter"></Twitter></h3>
    <p>{`Fulltime Fantasy hosts an entire `}<a parentName="p" {...{
        "href": "https://fulltimefantasy.com/pods/",
        "target": "_blank",
        "rel": "nofollow"
      }}>{`network`}</a>{` of over a dozen podcasts. `}<a parentName="p" {...{
        "href": "https://fulltimefantasy.com/dr-roto/",
        "target": "_blank",
        "rel": "nofollow"
      }}>{`"Dr. Roto"`}</a><Twitter handle="drroto" mdxType="Twitter"></Twitter>{` was the 2011 FSWA 2011 Newcomer of the Year, and leads the staff. `}<a parentName="p" {...{
        "href": "https://fulltimefantasy.com/shawn-childs/",
        "target": "_blank",
        "rel": "nofollow"
      }}>{`Shawn Childs`}</a><Twitter handle="shawn__childs" mdxType="Twitter"></Twitter>{`has played high-stakes fantasy since 2004. `}<a parentName="p" {...{
        "href": "https://fulltimefantasy.com/adam-ronis/",
        "target": "_blank",
        "rel": "nofollow"
      }}>{`Adam Ronis`}</a><Twitter handle="adamronis" mdxType="Twitter"></Twitter>{`co-hosts a `}<a parentName="p" {...{
        "href": "https://www.siriusxm.com/fantasysportsradio",
        "target": "_blank",
        "rel": "nofollow"
      }}>{`SiriusXM`}</a>{` radio show each week with Lisa Ann.`}</p>
    <h3><a parentName="h3" {...{
        "href": "https://www.SleeperU.com",
        "target": "_blank",
        "rel": "nofollow"
      }}>{`SleeperU`}</a>{` `}<Twitter handle="fakesmitty" mdxType="Twitter"></Twitter></h3>
    <p>{`SleeperU is a one-man sensation, the home of popular live streamer and `}<a parentName="p" {...{
        "href": "https://www.youtube.com/thefantasyfootballshow/",
        "target": "_blank",
        "rel": "nofollow"
      }}>{`YouTube personality`}</a>{` "Smitty". Since 2004, he also co-hosts weekly on Phoenix's CBS Radio affiliate `}<a parentName="p" {...{
        "href": "https://www.1580thefanatic.com/red-zone-russ-bliss/",
        "target": "_blank",
        "rel": "nofollow"
      }}>{`1580AM`}</a>{`. Smitty prides himself on bold predictions, identifying emerging talent before other analysts, and a high level of interaction with subscribers and listeners. His content is also available via `}<a parentName="p" {...{
        "href": "https://www.iheart.com/podcast/269-the-fantasy-football-show-30890497/",
        "target": "_blank",
        "rel": "nofollow"
      }}>{`podcast`}</a>{`.`}</p>
    <h2>{`Why these experts? What about `}{`[my favorite site]`}{` ?`}</h2>
    <p>{`The Judge's list represents a variety of sources, experience, analytic approaches, and combines popular mass-market networks with small independent sites. The Judge acknowledges that there are many other sites worthy of review, and he hopes to expand his verdicts to cover more sites. Ultimately, the Judge is seeking the best sites to recommend to his readers, and the more sites he covers, the more likely he is to find the best.`}</p>
    <h2>{`Can you add my favorite site to your verdicts?`}</h2>
    <p>{`Possibly. Contact the Judge and let him know what site you recommend, or work for.`}</p>
    <h2>{`What will disqualify a site from coverage by the Judge?`}</h2>
    <p>{`Sites that charge over $50 per season to see their rankings. The Judge isn't saying those sites aren't worth the extra money. But the majority of readers aren't going to shell out that much, when there are less expensive options with some terrific analysis. If you are a high-end player who is willing to pay a premium, the Judge recommends you do your own research to choose the best site for you.`}</p>
    <p>{`Sites that don't provide simple, weekly player rankings. It's great that you publish lots of sit/start articles, love/hate articles, sleeper lists, waiver wire tips, and everything else. But at the end of the day the Judge (like other accuracy monitors) needs an objective way to qualify your rankings. After all, fantasy owners have to make distinct choices, there's no points awarded for how much you liked a guy's matchup this week if you ultimately did not decide to start him. As analysts, if you believe a lesser-known player is poised for a big game, move him up in your rankings.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      